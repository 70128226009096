import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './PageNavbarMobile.css';
import logo from '../../../images/logo.webp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search'; 
import { MeiliSearch } from 'meilisearch'; 
import { useLocation } from 'react-router-dom';

const PageNavbarMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false); // Solo para abrir/cerrar la barra de búsqueda
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]); // Mantiene los resultados
  const [noResults, setNoResults] = useState(false);
  const [showResults, setShowResults] = useState(false); // Controla la visibilidad de los resultados de búsqueda

  const searchResultsRef = useRef(null);
  const searchBarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (showResults && searchBarRef.current && searchResultsRef.current) {
      // Calcula la posición de la barra de búsqueda
      const rect = searchBarRef.current.getBoundingClientRect();
      // Ajusta el estilo "top" del contenedor de resultados para que esté justo debajo de la barra de búsqueda
      searchResultsRef.current.style.top = `${rect.bottom+10}px`;
    }
  }, [showResults]);
  

  useEffect(() => {
    setSearchOpen(false);
    setShowResults(false); // Cerrar los resultados al cambiar de página
  }, [location]);

  const client = new MeiliSearch({
    host: process.env.REACT_APP_MEILI_HOST,
    apiKey: process.env.REACT_APP_MEILI_KEY,
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.style.overflow = menuOpen ? 'auto' : 'hidden';
  };

  // Manejador para el input de búsqueda
  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setShowResults(false); // Ocultar resultados mientras se escribe
  };

  // Función para ejecutar la búsqueda (presionando Enter o realizando acción de búsqueda)
  const handleSearch = async () => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      setNoResults(false);
      return;
    }

    try {
      const index = client.index('modelo');
      const results = await index.search(searchQuery, { limit: 5 });

      if (results.hits.length > 0) {
        setSearchResults(results.hits);
        setNoResults(false);
      } else {
        setSearchResults([]);
        setNoResults(true);
      }

      setShowResults(true); // Mostrar los resultados solo cuando se termina la búsqueda
    } catch (error) {
      console.error('Error al realizar la búsqueda:', error);
      setNoResults(true);
      setShowResults(true);
    }
  };

  const handleViewMore = () => {
    navigate('/productos');
    setShowResults(false); // Cierra los resultados al navegar
  };

  // Abre o cierra la barra de búsqueda
  const toggleSearch = () => {
    setSearchOpen(!searchOpen); // Cambia el estado de la barra de búsqueda
    setShowResults(false); // Cierra los resultados de búsqueda al cerrar la barra
    setSearchQuery(''); // Limpiar la consulta al cerrar la barra de búsqueda
    setSearchResults([]);
    setNoResults(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSearchOpen(false); // Cierra la barra de búsqueda
        setShowResults(false); // Oculta los resultados de búsqueda
        setSearchQuery('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="page-navbar-mobile">
      <div className="page-navbar-mobile-content">
        <div className="page-navbar-mobile-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        <div className="page-navbar-mobile-search">
          <SearchIcon 
            className="page-navbar-mobile-search-icon" 
            onClick={toggleSearch} // Abre la barra de búsqueda
          />
        </div>

        {searchOpen && ( // Solo abre la barra de búsqueda, no muestra resultados
          <div className="page-navbar-mobile-search-modal">
            <div className="page-navbar-mobile-search-bar" ref={searchBarRef}>
              <input
                type="text"
                value={searchQuery}
                onChange={handleInputChange} // Actualiza el input
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()} // Busca al presionar Enter
                placeholder="Busca nuestros productos"
              />
            </div>
          </div>
        )}

        {showResults && ( 
          <div className="page-navbar-mobile-search-results" ref={searchResultsRef}>
            {noResults ? (
              <div className="page-navbar-mobile-search-no-results">No se encontraron resultados</div>
            ) : (
              searchResults.map((result) => (
                <div key={result.id} className="page-navbar-mobile-search-result-item">
                  <Link to={`/modelo/${result.slug}`} onClick={handleViewMore}>
                    {result.nombre}
                  </Link>
                </div>
              ))
            )}
            

            <div className="ver-mas-productos" onClick={handleViewMore}>
              Ver más productos
            </div>
          </div>
        )}

        <div className="page-navbar-mobile-hamburger" onClick={toggleMenu}>
          {menuOpen ? <CloseIcon /> : <MenuIcon />}
        </div>

        {menuOpen && (
          <div className="page-navbar-mobile-menu">
            <Link to="/" onClick={toggleMenu}>INICIO</Link>
            <Link to="/productos" onClick={toggleMenu}>PRODUCTOS</Link>
            <Link to="/noticias" onClick={toggleMenu}>NOTICIAS</Link>
            <Link to="/contacto" onClick={toggleMenu}>CONTACTO</Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default PageNavbarMobile;