import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './PageNavbarDesktop.css';
import logo from '../../../images/logo.webp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search'; 
import CloseSearchIcon from '@mui/icons-material/HighlightOff';
import SearchButtonIcon from '@mui/icons-material/Search';  
import { MeiliSearch } from 'meilisearch'; 
import { useLocation } from 'react-router-dom';
import Submenu from './Submenu'; // Ensure Submenu component is imported

const PageNavbarDesktop = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const submenuTimeoutRef = useRef(null);
  const [isSection1, setIsSection1] = useState(true); // Iniciar en true porque al montar estamos en la sección 1
  const [forceNormalNavbar, setForceNormalNavbar] = useState(false);

  const searchResultsRef = useRef(null);
  const searchBarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setSearchOpen(false);
  }, [location]);

  // Escuchar el evento 'sectionChange' para actualizar el estado de la sección
  useEffect(() => {
    const handleSectionChange = (event) => {
      setIsSection1(event.detail === 0); // Cambia el estado según la sección
      if (event.detail === 0 && !forceNormalNavbar) {
        setForceNormalNavbar(false); // Si estamos en la sección 1 y no hay overrides, restaurar transparencia
      }
    };

    document.addEventListener('sectionChange', handleSectionChange);

    return () => {
      document.removeEventListener('sectionChange', handleSectionChange);
    };
  }, [forceNormalNavbar]);

  const client = new MeiliSearch({
    host: process.env.REACT_APP_MEILI_HOST,
    apiKey: process.env.REACT_APP_MEILI_KEY,
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.style.overflow = menuOpen ? 'auto' : 'hidden';
  };

  const handleSearch = async () => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      setNoResults(false);
      return;
    }

    setIsSearching(true);

    try {
      const index = client.index('modelo');
      const results = await index.search(searchQuery, { limit: 5 });

      if (results.hits.length > 0) {
        setSearchResults(results.hits);
        setNoResults(false);
      } else {
        setSearchResults([]);
        setNoResults(true);
      }

      setSearchOpen(true); // Mostrar resultados de búsqueda cuando comienza
      setForceNormalNavbar(true); // Forzar el estilo normal cuando la búsqueda comienza

    } catch (error) {
      console.error('Error durante la búsqueda:', error);
      setNoResults(true);
    } finally {
      setIsSearching(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleSearch = () => {
    if (searchOpen) {
      setSearchOpen(false);
      setSearchResults([]);
      setNoResults(false);
      setSearchQuery('');
      if (isSection1) {
        setForceNormalNavbar(false); // Volver al estilo transparente si se cierra la búsqueda y estamos en la sección 1
      }
    } else {
      setSearchOpen(true);
      setForceNormalNavbar(true); // Forzar el estilo normal cuando la búsqueda se abre
    }
  };

  const handleViewMore = () => {
    navigate('/productos');
    toggleSearch();
  };

  // Cambiar el estilo a normal cuando se hace hover sobre "Productos"
  const handleMouseEnter = () => {
    if (submenuTimeoutRef.current) {
      clearTimeout(submenuTimeoutRef.current);
      submenuTimeoutRef.current = null;
    }
    setSubmenuOpen(true);
    setForceNormalNavbar(true); // Forzar el estilo normal del navbar
  };

  // Volver al estilo transparente si se cierra el submenu y seguimos en la sección 1
  const handleMouseLeave = () => {
    submenuTimeoutRef.current = setTimeout(() => {
      setSubmenuOpen(false);
      if (isSection1) {
        setForceNormalNavbar(false); // Volver al estilo transparente
      }
    }, 200);
  };

  // Detectar clics fuera del área de búsqueda
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSearchOpen(false);
        setSearchQuery('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className={`page-navbar ${isSection1 && !forceNormalNavbar ? 'page-navbar-transparent' : ''}`}>
      <div className="page-navbar-content">
        <div className="page-navbar-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        <div className={`page-navbar-links-desktop ${isSection1 && !forceNormalNavbar ? 'page-navbar-white-links' : ''}`}>
          <Link to="/">INICIO</Link>
          <div
            className="navbar-productos-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Link to="/productos" className="navbar-productos">PRODUCTOS</Link>
            {submenuOpen && <Submenu onClose={() => setSubmenuOpen(false)} />} {/* Submenu rendering */}
          </div>
          <Link to="/noticias">NOTICIAS</Link>
          <Link to="/contacto">CONTACTO</Link>

          <div className="page-navbar-search" ref={searchBarRef}>
            {searchOpen ? (
              <div className="page-navbar-search-bar">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Busca nuestros productos"
                />
                <SearchButtonIcon
                  className={`page-navbar-search-button-icon ${isSearching ? 'active' : ''}`}
                  onClick={handleSearch}
                />
                <CloseSearchIcon className="page-navbar-search-close-icon" onClick={toggleSearch} />
              </div>
            ) : (
              <SearchIcon className="page-navbar-search-icon" onClick={toggleSearch} />
            )}
          </div>
        </div>

        {searchOpen && searchQuery && (
          <div className="page-navbar-search-results" ref={searchResultsRef}>
            {noResults ? (
              <div className="page-navbar-search-no-results">No se encontraron resultados</div>
            ) : (
              searchResults.slice(0, 5).map((result) => (
                <div key={result.id} className="page-navbar-search-result-item">
                  <Link to={`/modelo/${result.slug}`} onClick={toggleSearch}>
                    {result.nombre}
                  </Link>
                </div>
              ))
            )}

            {searchQuery && (
              <div className="page-navbar-search-result-item page-navbar-search-view-more" onClick={handleViewMore}>
                Ver más productos
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default PageNavbarDesktop;
